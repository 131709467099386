<template>
  <div>
    <div id="page-dummy"></div>
    <div id="page-about" class="page-about">
      <div class="img-seven-wrapper col col-1">
        <img class="img-seven" src="../assets/img/seven.gif" />
      </div>
      <div class="col col-2">
        <div class="discription">
          <!-- <img class="github-icon" :src="require(`@/assets/img/logo_1.svg`)" @click="onClickGit"/> -->
          <div class="name">
            <span class="tx-green">S</span>even <span class="tx-orange">X</span>u
          </div>
          <p>
            is a part of <span class="tx-green">software developer</span>, a part
            of <span class="tx-orange">artist</span> based in Bay area.
          </p>
          <p>
            Graduated from
            <span class="tx-green">Carnegie Mellon University</span>, She develops
            exceptional websites and applications that provide intuitive,
            pixel-perfect user interfaces with efficient and modern backends.
          </p>
        </div>

        <div class="skill">
          <p class="tx-skill1">She's been working with:</p>
          <div class="skill-block" v-for="obj in skills" :key="obj">
            <span class="material-icons i-skill">arrow_right</span>{{ obj }}
          </div>
          <br>
          <div class="skill-block">
            <span class="material-icons i-skill">arrow_right</span>
            <span class="flashing">_</span>
          </div>
        </div>
      </div>
    </div>

    <!-- work module -->
    <div id="page-works" class="page-works">
      <Works :works="works" />
    </div>

    <div id="page-contact" class="page-contact">
      <div class="contact-container">
        <div class="t-contact">Contact</div>
        <div class="contact-item">C : (+1) 412 419 5890</div>
        <div class="contact-item">E : xu@sevenxw.com</div>
        <div class="contact-item">A : Santa Clara, CA, 95054</div>
      </div>
    </div>

    <div class="desktop-menu">
      <a class="desktop-menu-item" href="#page-dummy">About <span class="tx-green">01</span></a>
      <a class="desktop-menu-item" href="#page-works">Work <span class="tx-green">02</span></a>
      <a class="desktop-menu-item" href="https://github.com/sevenxmonkey" target="_blank">GitHub <span class="tx-green">03</span></a>
      <a class="desktop-menu-item" href="#page-contact">Contact <span class="tx-green">04</span></a>
      <a class="desktop-menu-item desktop-resume" target="_blank"
        href="https://drive.google.com/file/d/1BC8mQ7x01b5oxGPoGqo8VNEi560HTVig/view?usp=sharing">Resume</a>
    </div>

    <div class="mobile-menu">
      <div v-show="!this.expandManu" class="menu" @click="onClickMenu">
        <span class="material-icons i-menu">menu</span>
      </div>
      <div v-show="this.expandManu" class="expand-menu">
        <div class="menu">
          <span class="material-icons i-menu" @click="onClickMenu">close</span>
        </div>
        <div class="expand-menu-container">
          <div>
            <a class="expand-menu-item" href="#page-dummy" @click="onClickMenu">
              <span class="tx-green">01</span> About
            </a>
            <a class="expand-menu-item" href="#page-works" @click="onClickMenu">
              <span class="tx-green">02</span> Work
            </a>
            <a class="expand-menu-item" href="https://github.com/sevenxmonkey" target="_blank">
              <span class="tx-green">03</span> GitHub
            </a>
            <a class="expand-menu-item" href="#page-contact" @click="onClickMenu">
              <span class="tx-green">04</span> Contact
            </a>
            <a class="expand-menu-item resume" target="_blank"
              href="https://drive.google.com/file/d/1BC8mQ7x01b5oxGPoGqo8VNEi560HTVig/view?usp=sharing">
              Resume
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Works from "../components/Works";
const workArray = require("../assets/workText.json");

export default {
  name: "HomePage",
  components: {
    Works,
  },
  data() {
    return {
      skills: [
        "JavaScript", "Java",
        "Node.js", "MySQL", "MongoDB", 
        "Vue", "React", "Angular9",
        "Puppeteer", "Google APIs", "Jenkins", "Git",  
        "SVG.js", "Fabric.js",
        "ElementUI", "SemanticUI", "MaterialUI", "OOP", "Algorithm"
      ],
      expandManu: false,
      works: [],
    };
  },
  mounted() {
    console.log(workArray);
    this.works = workArray.works;
  },
  methods: {
    onClickMenu() {
      this.expandManu = !this.expandManu;
    },
    onClickGit(){
      window.open('https://github.com/sevenxmonkey', '_blank');
    }
  },
};
</script>

<style scoped>
/* page about */
.img-seven-wrapper {
  overflow: hidden;
}
.img-seven {
  width: 130vw;
  position: relative;
  left: -30vw;
}

.discription,
.skill {
  position: relative;
  padding: 10px 25px;
  line-height: 1.5;
}

.name {
  font-size: 3em;
}

.skill {
  color: hsla(0, 0%, 100%, 0.7);
  font-size: 16px;
}

.skill-block {
  display: inline-block;
  width: 150px;
}

.i-skill {
  color: #0f0;
  font-size: 20px;
  position: relative;
  top: 3px;
}

.flashing {
  color: rgb(0, 255, 0);
  -webkit-animation: blinker 1s linear infinite;
  animation: blinker 1s linear infinite;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

/** Desktop menu */

.desktop-menu{
  display: none;
  width: 10vw;
  position: fixed;
  top: 40vh;
  right: 40px;
  text-align: right;
}

.desktop-menu-item{
  margin-top: 1em;
  font-size: 18px;
  color: white;
  display: block;
  text-decoration: none;
}

.desktop-menu-item:hover{
  cursor: pointer;
  color: rgba(0, 255, 0);
}

.desktop-resume{
  display: inline-block;
  color: rgb(0, 255, 0);
  border: 1px solid rgb(0, 255, 0);
  border-radius: 5px;
  margin-top: 30px;
  padding: 10px;
}

.desktop-resume:hover{
  color: black;
  font-weight: 800;
  background-color: rgb(0, 255, 0);
}


/** Mobile Menu */
.mobile-menu .menu {
  position: fixed;
  top: 15px;
  right: 15px;
  width: 45px;
  height: 45px;
  border-radius: 100px;
  background-color: rgba(0, 255, 0, 0.7);
  border: 3px solid rgba(0, 0, 0);
}

.mobile-menu .i-menu {
  color: rgba(0, 0, 0);
  font-size: 35px;
  position: relative;
  left: 5px;
  top: 5px;
}

.mobile-menu .expand-menu {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background-color: rgba(0, 0, 0, 0.9);
}

.mobile-menu .expand-menu-container {
  /* display: flex; */
  width: 30vw;
  margin: 30vh auto;
}

.mobile-menu .expand-menu-item {
  color: white;
  display: block;
  margin-top: 40px;
  text-decoration: none;
}

.mobile-menu .resume{
  display: inline-block;
  color: rgb(0, 255, 0);
  border: 1px solid rgb(0, 255, 0);
  border-radius: 5px;
  padding: 10px;
}

/** Works */

.page-works {
  padding-top: 30px;
}

/** Contact */

/* .page-contact{
  height: 70vh;
} */

.page-contact .contact-container{
  width: 200px;
  margin-left: calc(50vw - 80px);
  margin: 42vh 0 42vh 30vw;
  /* margin: 30vh 0 0 18vh; */
}

.t-contact{
  color: #0f0;
  font-weight: 500;
  font-size: 28px;
}

.contact-item{
  margin: 20px 0;
  color: rgba(255, 255, 255, 0.8);
  font-size: 14px;
}

.github-icon{
  width: 25px;
  position:absolute;
  left: 285px;
  top: 20px;
  cursor: pointer;
  filter: invert(64%) sepia(75%) saturate(2632%) hue-rotate(74deg) brightness(108%) contrast(138%);
  opacity: 70%;
}

.github-icon:hover{
  opacity: 100%;
}

@media only screen and (min-width: 750px) {
  .mobile-menu{
    display: none;
  }

  .desktop-menu{
    display: block;
  }

  .page-about{
    padding-right: 10vw;
    display: flex;
  }

  .col{
    display: inline-block;
  }
  .col-1{
    margin-top: 18vh;
    max-width: 800px;
  }
  .col-2{
    margin-top: 25vh;
    flex-grow: 5;
  }
  .img-seven{
    max-width: 100%;
    height: auto;
    left: 0px;
  }
  .name{
    font-size: 90px;
  }

  .page-contact .contact-container{
    width: auto;
    margin-left: calc(50vw - 150px);
  }

  .t-contact{
    font-size: 36px;
  }
  .contact-item{
    font-size: 18px;
  }

  .page-works {
    margin-top: 20vh;
  }

  .github-icon{
    left: 450px;
    width: 30px;
  }
  
}
</style>