<template>
  <div id="app">
    <HomePage />
  </div>
</template>

<script>
import HomePage from './pages/index.vue'

export default {
  name: 'App',
  components: {
    HomePage
  }
}
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Comfortaa:wght@300&display=swap");
@import url("https://fonts.googleapis.com/icon?family=Material+Icons");
#app {
  font-family: "Comfortaa",sans-serif;
  color: #FFFFFFE6;
  font-size: 18px;
  font-weight: 100;
  box-sizing: border-box;
}

html {
  scroll-behavior: smooth;
}

.tx-green{
  color: #0f0;
}
.tx-orange{
  color: #ff7b00;
}
</style>
